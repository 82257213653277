<template>
  <v-app>
    <v-app-bar
      class=""
      flat
      dense
      dark
      fixed
      app
      height="70"
    >
      <v-btn
        text
        tile
        class="logoteam mx-md-auto px-2"
        href="#"
      >
        <v-img
          height="68"
          contain
          :src="require(`@/assets/logo-school-white.svg`)"
        />
      </v-btn>
      <v-menu
        offset-y
        class="d-md-none"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            color="white"
            dark
            v-bind="attrs"
            v-on="on"
            class="d-md-none ml-auto"
          >
            <v-icon
              large
            >
              mdi-menu
            </v-icon>
          </v-btn>
        </template>
        <v-list
          class="d-md-none"
          color="#272727"
        >
          <v-list-item
            v-for="(item, index) in menu"
            :key="index"
            :href="item.href"
            :target="item.target ? item.target : '_self'"
          >
            <v-list-item-title class="white--text">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div
        class="top-menu d-none d-md-flex justify-center flex-grow-0 mx-auto fill-height"
      >
        <v-btn
          text
          tile
          v-for="(item, index) in menu"
          :key="index"
          :href="item.href"
          :target="item.target ? item.target : '_self'"
          class="white--text text-none"
        >
          {{ item.title }}
        </v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: 'App',

    mounted() {
      this.setAppHeightGlobalCssVariable();
    },

    data: () => ({
      menu: [
        {
          title: 'Программа соревнований',
          href: '#program'
        },
        {
          title: 'Положение',
          href: '#info'
        },
        {
          title: 'Список участников',
          href: '#list',
        },
        {
          title: 'Контакты',
          href: '#contacts'
        }
      ]
    }),

    methods: {
      setAppHeightGlobalCssVariable() {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`);
      },
    },
  };
</script>

<style lang="scss">
  * {
    font-family: 'Ubuntu', sans-serif;
  }

  .top-menu {
    width: auto !important;
    .text-body-1 {
      font-family: 'Ubuntu', sans-serif;
      font-weight: 600;
    }

    .v-btn {
      height: 100% !important;
    }
  }

  .logoteam {
    height: 100% !important;
    width: 200px;
    z-index: 2;
    cursor: pointer;
  }
</style>

